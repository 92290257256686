.photo
    width: 100%
    height: auto
    min-height: 500px

    &__dev-mode
        background-color: #f8f9fa
        border: 1px solid #dee2e6
        border-radius: 4px
        margin: 10px 0
        padding: 8px 12px
        text-align: center
        color: #343a40
        font-size: 14px

        span
            display: inline-block
            padding: 2px 8px

    &__initial-loading
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        min-height: 200px
        margin-top: 50px

    &__loading-spinner
        text-align: center
        padding: 20px
        font-size: 1.5em

    &__spinning-icon
        width: 50px
        height: 50px
        animation: spin 2s linear infinite

    &__error
        text-align: center
        padding: 30px

        button
            margin-top: 15px
            padding: 8px 16px
            background-color: #4a4a4a
            color: white
            border: none
            border-radius: 4px
            cursor: pointer

            &:hover
                background-color: #333

    &__no-images
        text-align: center
        padding: 30px
        color: #666

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)