.image-modal
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    z-index: 9999
    opacity: 0
    transition: opacity 0.3s ease-in-out
    overflow: hidden
    box-sizing: border-box
    pointer-events: none  // Allow clicks to pass through to the backdrop

    @media (orientation: landscape)
        width: 100%
        max-width: 100%
        height: 100%
        max-height: 100%
        margin: 0
        padding: 0
        left: 0
        right: 0
        top: 0
        bottom: 0

    &__backdrop
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        width: 100%
        height: 100%
        background-color: rgba(0, 0, 0, 0.9)
        z-index: 9998  // Just below the modal
        cursor: pointer

    &__content
        position: relative
        display: flex
        justify-content: center
        align-items: center
        box-sizing: border-box
        width: auto
        height: auto
        max-width: 95%
        max-height: 95%
        margin: 0 auto
        pointer-events: auto  // Restore pointer events for content

        @media (orientation: landscape)
            max-width: 90%
            max-height: 90%

    img
        max-width: 100%
        max-height: 100%
        object-fit: contain
        user-select: none
        -webkit-user-drag: none

        @media (orientation: landscape)
            max-width: 90vw
            max-height: 80vh

    &__close
        position: absolute
        top: 10px
        right: 10px
        background: rgba(0, 0, 0, 0.5)
        border: none
        color: white
        font-size: 24px
        cursor: pointer
        width: 40px
        height: 40px
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        z-index: 110
        opacity: 0
        transition: opacity 0.3s ease-in-out
        pointer-events: auto  // Restore pointer events for button

        @media (orientation: landscape)
            top: 5px
            right: 5px
            font-size: 20px
            width: 36px
            height: 36px

    &__nav-button
        position: absolute
        top: 50%
        transform: translateY(-50%)
        background: rgba(0, 0, 0, 0.5)
        color: white
        border: none
        font-size: 40px
        height: 60px
        width: 40px
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        z-index: 110
        opacity: 0
        transition: opacity 0.3s ease-in-out
        pointer-events: auto  // Restore pointer events for buttons

        &--left
            left: 5px
            border-radius: 0 5px 5px 0

        &--right
            right: 5px
            border-radius: 5px 0 0 5px

        @media (orientation: landscape)
            font-size: 32px
            height: 50px
            width: 32px

            &--left
                left: 2px

            &--right
                right: 2px

    // Show controls when modal is active and hovered
    &.controls-visible,
    &:hover
        .image-modal__close,
        .image-modal__nav-button
            opacity: 1

    // Override for mobile
    @media (hover: none)
        .image-modal__close,
        .image-modal__nav-button
            opacity: 0.8

            &.fade-out
                opacity: 0
