.image-grid
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
    gap: 10px
    padding: 10px
    width: 100%
    box-sizing: border-box
    max-width: 100vw

    @media (min-width: 768px)
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr))
        gap: 15px
        padding: 15px

    @media (min-width: 1200px)
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr))
        gap: 20px
        padding: 20px

    @media (max-width: 400px)
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr))
        gap: 10px
        padding: 10px

    &__item
        width: 100%
        padding-top: 100% // 1:1 aspect ratio
        background-size: cover
        background-position: center
        cursor: pointer
        border-radius: 3px
        overflow: hidden
        position: relative

        &:hover
            transform: scale(1.01)
            transition: transform 0.2s ease